<template>
  <a-modal
    :visible="visible"
    title="编辑"
    width="440px"
    :mask-closable="false"
    @ok="handleOk"
    @cancel="handleCancel"
    centered
  >
    <a-form
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 18 }"
      ref="formRef"
      :model="form"
      :rules="rules"
    >
      <a-form-item label="姓名" name="userName">
        <a-input v-model:value="form.userName" placeholder="请输入姓名" />
      </a-form-item>
      <a-form-item label="昵称" name="nickName">
        <a-input v-model:value="form.nickName" placeholder="请输入昵称" />
      </a-form-item>
      <a-form-item label="手机" name="mobileNum">
        <a-input
          v-model:value="form.mobileNum"
          placeholder="请输入手机"
          disabled
        />
      </a-form-item>
      <a-form-item label="邮箱" name="email">
        <a-input v-model:value="form.email" placeholder="请输入邮箱" disabled />
      </a-form-item>
      <a-form-item label="公司" name="company">
        <a-input v-model:value="form.company" placeholder="请输入公司" />
      </a-form-item>
      <a-form-item label="工号" name="staffNo">
        <a-input v-model:value="form.staffNo" placeholder="请输入工号" />
      </a-form-item>

      <a-form-item label="省" name="province">
        <a-select v-model:value="form.province" placeholder="请选择">
          <a-select-option
            v-for="item in provinceList"
            :key="item.itemNameCn"
            :value="item.itemNameCn"
            @click="handleClickByUrlType(item)"
          >
            {{ item.itemNameCn }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item label="市" name="city">
        <a-select v-model:value="form.city" placeholder="请选择">
          <a-select-option
            v-for="item in cityList"
            :key="item.itemNameCn"
            :value="item.itemNameCn"
          >
            {{ item.itemNameCn }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item label="关系类型" name="companyRelation">
        <a-select v-model:value="form.companyRelation" placeholder="请选择">
          <a-select-option
            v-for="item in companyRelationList"
            :key="item.itemNameCn"
            :value="item.itemNameCn"
          >
            {{ item.itemNameCn }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item label="性别" name="gender ">
        <a-radio-group v-model:value="form.gender">
          <a-radio class="single-line" :key="1" value="0">
            {{ '男' }}
          </a-radio>
          <a-radio class="single-line" :key="0" value="1">
            {{ '女' }}
          </a-radio>
        </a-radio-group>
      </a-form-item>

      <a-form-item label="营销资讯" name="receiveConsult">
        <a-radio-group v-model:value="form.receiveConsult">
          <a-radio class="single-line" :key="1" :value="true" disabled>
            {{ '接受' }}
          </a-radio>
          <a-radio class="single-line" :key="0" :value="false" disabled>
            {{ '不接受' }}
          </a-radio>
        </a-radio-group>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { reactive, watch, toRaw, toRefs, ref } from 'vue';
import { Form, Select, Radio } from 'ant-design-vue';
import { mobileRegExp, emailRegExp } from '@/utils/validation';
import websiteUserApi from '@/api/websiteUser';

export default {
  components: {
    AForm: Form,
    AFormItem: Form.Item,
    ASelect: Select,
    ASelectOption: Select.Option,
    ARadio: Radio,
    ARadioGroup: Radio.Group,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    initValue: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, context) {
    const formRef = ref();
    const form = reactive({
      id: '',
      userName: '',
      mobileNum: '',
      email: '',
      province: '',
      city: '',
      company: '',
      companyRelation: '',
      receiveConsult: 1,
      gender: '',
      nickName: '',
    });
    const state = reactive({
      dictionary: {},
      provinceList: [],
      cityList: [],
      companyRelationList: [],
    });
    const required = { required: true, message: '不能为空' };
    const rules = reactive({
      userName: [required, { max: 64, message: '不可超过64个字符' }],
      mobileNum: [
        required,
        { pattern: mobileRegExp, message: '请输入正确的电话号码' },
      ],
      email: [
        required,
        { pattern: emailRegExp, message: '请输入正确的邮箱地址' },
      ],
      company: [required],
      companyRelation: [required],
      province: [required],
      city: [required],
      receiveConsult: [required],
      gender: [required],
    });

    watch(
      () => props.initValue,
      initValue => {
        Object.assign(form, initValue);
        state.newEmail = form.email;
      }
    );

    watch(
      () => form.province,
      () => {
        form.province &&
          (state.cityList = state.provinceList.find(
            i => i.itemCode === form.province
          ).children);
      }
    );

    // 获取下拉框数据
    websiteUserApi.getItemTree('', { code: 'CHINA_AREA' }).then(data => {
      state.provinceList = data;
    });
    // 与本公司关系
    websiteUserApi
      .getItemTree('', { code: 'WEBSITE_USER_COMPANY_RELATIONSHIP' })
      .then(data => {
        state.companyRelationList = data;
      });

    const handleClickByUrlType = val => {
      state.dictionary['city'] = val.children;
      form.city = null;
    };

    return {
      formRef,
      form,
      rules,
      ...toRefs(state),
      handleClickByUrlType,

      handleOk: () => {
        formRef.value
          .validateFields()
          .then(() => {
            context.emit('fnOk', toRaw(form));
          })
          .catch(e => {
            console.log('e', e);
          });
      },

      handleCancel: () => {
        // 重置表单
        formRef.value.resetFields();
        context.emit('update:visible', false);
      },
    };
  },
};
</script>

<style scoped></style>
