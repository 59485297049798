<template>
  <div class="iss-main-grid">
    <div class="iss-search-wrap">
      <search-form :items="items" @fnSearch="handleFnSearch" />
    </div>
    <div class="iss-grid-wrap mt-16">
      <grid
        ref="gridRef"
        allow-selection
        :columns="columns"
        :code="$route.path"
        :url="url"
        :search="search"
        :btn-operation="btnOperation"
        :scroll="{ x: 1590, y: gridHeight }"
      >
        <template #userName="{ record }">
          <div class="user-name flex">
            <div class="img-box">
              <img v-if="record.headImgUrl" :src="record.headImgUrl" alt="" />
              <img v-else src="../../../assets/images/headImg.png" alt="" />
            </div>
            <div class="flex-1">{{ record.userName }}</div>
          </div>
        </template>
        <!--        <template #headImgUrl="{ text }">-->
        <!--          <div class="img-box">-->
        <!--            <img :src="text" alt="" v-if="text" />-->
        <!--          </div>-->
        <!--        </template>-->
        <template #operation="{ record }">
          <operation :options="options" :record="record" />
        </template>
        <template #receiveConsult="{ text }">
          <a-tag v-if="text === true" color="success">接受</a-tag>
          <a-tag v-else-if="text === false" color="error">不接受</a-tag>
          <span v-else>{{ text }}</span>
        </template>
        <template #gender="{ text }">
          <span v-if="text === '0'">男</span>
          <span v-else-if="text === '1'">女</span>
        </template>
      </grid>
    </div>
  </div>
  <iss-edit
    v-model:visible="editVisible"
    :init-value="activeItem"
    @fnOk="handleFnOkByEdit"
  />
</template>

<script>
import { reactive, ref, toRefs } from 'vue';
import { message, Tag } from 'ant-design-vue';
import SearchForm from '@/components/searchForm';
import Grid from '@/components/grid';
import Operation from '@/components/operation';
import { downloadExcel } from '@/utils';
import websiteUserApi from '@/api/websiteUser.js';
import dictionaryApi from '@/api/dictionary.js';
import IssEdit from './components/edit';

export default {
  components: {
    SearchForm,
    Grid,
    Operation,
    IssEdit,
    ATag: Tag,
  },
  setup() {
    const gridRef = ref();
    const state = reactive({
      search: {},
      editVisible: false,
      activeItem: {},
    });

    const importResult = (data, closeSetInterval) => {
      if (data.result === 1) {
        clearInterval(closeSetInterval);
        message.success({
          content: `导入成功，成功${data.successCount}条，失败${data.failCount}条。`,
        });
        gridRef.value.refreshGrid();
      } else if (data.result === -1) {
        clearInterval(closeSetInterval);
        message.error({ content: '导入失败' });
        // context.emit('fnOk');
      }
    };

    const getImportResult = params => {
      let count = 0;
      let closeSetInterval = setInterval(function () {
        if (count < 50) {
          count++;
          websiteUserApi.getImportWebsiteUserData('', params).then(data => {
            importResult(data, closeSetInterval);
          });
        } else {
          // 清除定时器
          clearInterval(closeSetInterval);
          message.error({ content: '导入失败' });
        }
      }, 10000);
    };

    const getUploadOption = url => ({
      name: 'multipartfile',
      accept: '.xlc,.xlm,.xls,.xlsx,.xlt,.xlw',
      action:
        process.env.VUE_APP_API_SERVER + process.env.VUE_APP_API_BASE_URL + url,
      onChange: ({ file }) => {
        if (file.status === 'done') {
          // const { count, successCount, failCount, error, result } =
          //   file.response.data;

          if (file.response.data) {
            let params = { key: file.response.data };
            message.info({
              content: '数据正在导入中...',
            });
            getImportResult(params);
          }
        } else if (file.status === 'error') {
          // message.error(`${info.file.name} file upload failed.`);
        }
      },
    });
    return {
      gridRef,
      ...toRefs(state),
      items: [
        { key: 'userName', label: '姓名' },
        { key: 'mobileNum', label: '手机' },
        { key: 'email', label: '邮箱' },
        { key: 'company', label: '公司' },
        {
          key: 'province',
          label: '省份',
          type: 'select',
          valueKey: 'itemNameCn',
          labelKey: 'itemNameCn',
          url: `${dictionaryApi.provinceUrl}?code=CHINA_AREA`,
          linkedKey: 'city',
        },
        {
          key: 'city',
          label: '城市',
          type: 'select',
          valueKey: 'itemNameCn',
          labelKey: 'itemNameCn',
        },
        {
          key: 'companyRelation',
          label: '关系类型',
          type: 'select',
          valueKey: 'itemCode',
          labelKey: 'itemNameCn',
          url: `${websiteUserApi.CompanyRelationShipTreeUrl}?code=WEBSITE_USER_COMPANY_RELATIONSHIP`,
        },
        {
          key: 'receiveConsult',
          label: '营销资讯',
          type: 'select',
          dataset: [
            { value: 0, label: '不接受' },
            { value: 1, label: '接受' },
          ],
        },
      ],
      columns: [
        // {
        //   dataIndex: 'headImgUrl',
        //   title: '',
        //   width: 40,
        //   slots: { customRender: 'headImgUrl' },
        // },
        {
          dataIndex: 'userName',
          title: '姓名',
          width: 180,
          slots: { customRender: 'userName' },
        },
        {
          dataIndex: 'nickName',
          title: '昵称',
          width: 120,
          ellipsis: true,
          customRender: ({ text }) => text || '-',
        },
        {
          dataIndex: 'mobileNum',
          title: '手机',
          width: 120,
          ellipsis: true,
          customRender: ({ text }) => text || '-',
        },
        {
          dataIndex: 'email',
          title: '邮箱',
          width: 180,
          ellipsis: true,
          customRender: ({ text }) => text || '-',
        },
        {
          dataIndex: 'company',
          title: '公司',
          width: 180,
          ellipsis: true,
          customRender: ({ text }) => text || '-',
        },
        {
          dataIndex: 'staffNo',
          title: '工号',
          width: 80,
          ellipsis: true,
          customRender: ({ text }) => text || '-',
        },
        { dataIndex: 'province', title: '省份', width: 120, ellipsis: true },
        { dataIndex: 'city', title: '城市', width: 120, ellipsis: true },
        {
          dataIndex: 'companyRelation',
          title: '关系类型',
          slots: { customRender: 'companyRelation' },
          width: 120,
          ellipsis: true,
        },
        {
          dataIndex: 'source',
          title: '来源',
          width: 120,
          customRender: ({ text }) => text || '-',
          ellipsis: true,
        },
        {
          dataIndex: 'receiveConsult',
          title: '营销资讯',
          slots: { customRender: 'receiveConsult' },
          width: 100,
          ellipsis: true,
        },
        {
          dataIndex: 'gender',
          title: '性别',
          slots: { customRender: 'gender' },
          ellipsis: true,
        },
        {
          dataIndex: 'createTime',
          title: '注册时间',
          width: 160,
          ellipsis: true,
        },

        {
          key: 'id',
          title: '操作',
          fixed: 'right',
          width: 110,
          slots: { customRender: 'operation' },
        },
      ],
      btnOperation: [
        {
          type: 'file',
          btnType: 'primary',
          label: '导入目标用户',
          icon: 'ImportOutlined',
          permission: 'contact:file',
          children: [
            {
              type: 'download',
              btnType: 'primary',
              icon: 'DownloadOutlined',
              permission: 'contact:download',
              label: '下载模板',
              extra: '请按照模板的格式准备要导入的数据',
              fnClick: () => {
                websiteUserApi
                  .downloadWebsiteUserTemplate('websiteUser:download')
                  .then(({ name, data }) => downloadExcel(name, data));
              },
            },
            {
              type: 'uplaod',
              btnType: '',
              permission: 'contact:upload',
              label: '上传文件',
              upload: getUploadOption(websiteUserApi.importWebsiteUserUrl),
              fnChange: fileList => {
                console.log(fileList);
              },
            },
          ],
        },
        {
          type: 'export',
          label: '导出',
          icon: 'ExportOutlined',
          permission: 'contact:export',
          fnClick: ids => {
            if (ids.length) {
              websiteUserApi
                .export('contact:export', [...ids])
                .then(({ name, data }) => downloadExcel(name, data));
            } else {
              message.info('请至少选中一条数据！');
            }
          },
        },
        {
          type: 'export',
          label: '全部导出',
          icon: 'ExportOutlined',
          permission: 'contact:export',
          fnClick: () => {
            websiteUserApi
              .exportAll('contact:exportAll', '')
              .then(({ name, data }) => downloadExcel(name, data));
          },
        },
      ],
      options: [
        {
          type: 'update',
          permission: 'websiteUser:update',
          fnClick: record => {
            state.editVisible = true;
            state.activeItem = { ...record };
            console.log('state.activeItem', state.activeItem);
          },
        },
        {
          type: 'delete',
          permission: 'websiteUser:delete',
          fnClick: ({ id }) => {
            websiteUserApi
              .delete('websiteUser:delete', { ids: [id] })
              .then(() => {
                message.success('操作成功');
                gridRef.value.refreshGrid();
              });
          },
        },
      ],
      url: websiteUserApi.pageUrl,
      gridHeight: document.body.clientHeight - 455,
      handleFnSearch: value => {
        const temp = Object.assign({}, value);
        // temp.tag = temp.tag?.join(',');
        state.search = temp;
      },
      handleFnOkByEdit: value => {
        // contactId
        websiteUserApi.update(`approval:update`, value).then(() => {
          state.editVisible = false;
          message.success('操作成功');
          gridRef.value.refreshGrid();
        });
      },
    };
  },
};
</script>

<style lang="less" scoped>
//.iss-main-grid {
//  max-height: calc(100vh - 72px);
//  overflow-y: auto;
//}
.contact-page {
  & .title {
    .right {
      i {
        margin-left: 3px;
      }
    }
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
//.iss-search-wrap /deep/ .ant-space-item {
//  margin-bottom: 20px;
//}
.user-name {
  .img-box {
    border: 1px solid #c9cdd4;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 8px;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}
</style>
